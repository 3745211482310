
import { defineComponent, PropType } from 'vue'

import { mapGetters } from 'vuex'

import ChooseBearItem from './choose-bear-item.vue'
import Load from '@/components/lazy-load.vue'

export default defineComponent({
  name: 'Modal',
  components: { ChooseBearItem, Load },
  data (){
    return {
      tokenId: 0
    }
  },
  props: {
    newCybersquadSlotId: {
      type: Number,
      require: true,
      default: 0
    },
		newCyberSquadeContainerId: {
			type: Number,
			require: true,
      default: 0
		},
    onTokenChosen: {
      type: Function,
      default: () => {""}
    },
    allowedTokens: {
      type: Array as PropType<Array<number>>,
      default: () => []
    }
  },

  methods: {
    parameterPassing(tokenId:number ):void {
      this.tokenId = +tokenId
      this.onTokenChosen(this.newCyberSquadeContainerId, this.newCybersquadSlotId, tokenId)
      this.$emit('clickTokesModal')
    }
  },

  computed: {
    ...mapGetters(['userTokensData'])
  }
})
