
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Card',
  props: {
    img: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    tag: {
      type: Array,
      require: true
    },
    price: {
      type: String,
      default: ''
    },
    delist: {
      type: Boolean,
      default: false
    }
  }
})
