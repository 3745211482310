
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'

import SquadeContainer from './components/squade-container.vue'

export default defineComponent({
  name: 'YourSquadesContainer',
  components: {  SquadeContainer },
  data () {
    return {
      activeBlockSquade: true
    }
  },

  methods: {
    ...mapActions(['fetchUserSquades'])
  },

  computed: {
    ...mapGetters(['getUserSquadesIds'])
  },

  async mounted() {
    await this.fetchUserSquades()
  }
})
