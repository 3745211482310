
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ChooseBearItem',
  props: {
    img: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'Helloy'
    },
    tokenId: {
      type: Number,
      require: true
    }
  }
})
