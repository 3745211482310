
import {defineComponent} from 'vue'
import {IArmyStatsContainer} from '@/helpers/interface'
import wallet from '@/lib/wallet'

export default defineComponent({
  name: 'ArmyStatsContainer',
  data(): IArmyStatsContainer {
    return {
      claimableCola: 0,
      colaBalance: 0,
      cybercolaBalance: 0,
      dailyIncome: 0,
      rename: 0,
      slogan: 0,
      amountDay: 0,
      cyberCola: 0
    }
  },
  methods: {
    async getClaimableCola(): Promise<void> {
      await wallet.cyberSquad_claimable()
          .then(i => this.claimableCola = wallet.formatUnits(i))
          .catch(err => console.error(err))
    },

    async getColaBalance(): Promise<void> {
      await wallet.cybercola_balanceOf()
          .then(i => this.colaBalance = wallet.formatUnits(i))
          .catch(err => console.error(err))
    },

    async currentDailyIncome(): Promise<any> {
      await wallet.cyberSquad_currentDailyIncomeByAddress()
          .then(i => this.dailyIncome = wallet.formatUnits(i))
          .catch(err => console.error(err))
    },

    async cyberSquadClaim(): Promise<any> {
      await wallet.cyberSquad_claim()
    },

    async renamePrice() {
      this.rename = wallet.formatUnits(await wallet.cyberSquade_priceRename())
    },

    async priceChangeSlogan() {
      this.slogan = wallet.formatUnits(await wallet.cyberSquade_priceChangeSlogan())
    },

    async priceExtendSquadesAmount() {
      this.amountDay = wallet.formatUnits(await wallet.cyberSquade_priceExtendSquadesAmount())
    },

    async extendSquadesAmount() {
      wallet.cyberSquade_extendSquadesAmount()
    }
  },

  mounted() {
    this.getClaimableCola()
    this.getColaBalance()
    this.currentDailyIncome()
    this.renamePrice()
    this.priceChangeSlogan()
    this.priceExtendSquadesAmount()
  }
})
