
import {defineComponent} from 'vue'
import {NewCybersquadBlocksContainerData} from '@/helpers/interface'
import {mapGetters} from 'vuex'
import {BigNumber} from 'ethers'

import NewCybersquadContainer from '@/pages/army/components/new-cybersquad-container/index.vue'
import wallet from '@/lib/wallet'

export default defineComponent({
  name: 'NewCybersquadBlocksContainer',
  components: {NewCybersquadContainer},
  data(): any {
    return {
      chosenTokens: {}
    }
  },
  methods: {
    setChosenToken(newCybersquadContainerId: number, newCybersquadSlotId: number, tokenId: number): void {
      if (!this.chosenTokens[newCybersquadContainerId]) {
        this.chosenTokens[newCybersquadContainerId] = {}
        this.chosenTokens[newCybersquadContainerId][newCybersquadSlotId] = tokenId
      } else {
        this.chosenTokens[newCybersquadContainerId][newCybersquadSlotId] = tokenId
      }
    }
  },

  computed: {
    ...mapGetters(['userTokensData', 'getNewCybersquadsAllowed']),

    allowedTokens(): any {
      let prohibitedTokens = []

      for (const containerId in this.chosenTokens) {
        let slotData = this.chosenTokens[containerId]
        for (const slotId in slotData) {
          let tokenId = slotData[slotId]
          if (tokenId) {
            prohibitedTokens.push(tokenId.toString())
          }
        }
      }

      let allowedTokens = []

      for (const tokenId in this.userTokensData) {
        if (!prohibitedTokens.includes(tokenId)) {
          allowedTokens.push(tokenId)
        }
      }

      return allowedTokens
    }
  }
})
