import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fe444c5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-12 col-12-md col-12-lg card card-squade" }
const _hoisted_2 = { class: "card-title" }
const _hoisted_3 = { class: "card-slogan" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "wrapper" }
const _hoisted_7 = { class: "card-count wrapper-title" }
const _hoisted_8 = { class: "card-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_slogan_modal = _resolveComponent("slogan-modal")!
  const _component_rename_modal = _resolveComponent("rename-modal")!
  const _component_disband_modal = _resolveComponent("disband-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.getSquade.name ? `${_ctx.getSquade.name} #${_ctx.squadeId.toString().padStart(4, "0")}` : `Cybersquade #${_ctx.squadeId.toString().padStart(4, "0")}`), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.getSquade.slogan), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSquadeBearz.bearz, (squade, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "col card-img card-img-a"
        }, [
          _createElementVNode("img", {
            src: _ctx.getSquadeTokensBearz[squade].metadata.image ? _ctx.getSquadeTokensBearz[squade].metadata.image : ''
          }, null, 8, _hoisted_5),
          _createElementVNode("h4", null, _toDisplayString(_ctx.getSquadeTokensBearz[squade].metadata.name ? _ctx.getSquadeTokensBearz[squade].metadata.name : ''), 1)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getDailyIncome) + " $CyberCola/Day", 1),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("a", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeModalRename = true)),
          class: "button button-small secondary btn-btn"
        }, " Rename "),
        _createElementVNode("a", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeModalSlogan = true)),
          class: "button button-small secondary btn-btn"
        }, " Slogan "),
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.activeModalDisband = true)),
          class: "button button-small third btn-btn"
        }, " Disband ")
      ])
    ]),
    _withDirectives(_createVNode(_component_slogan_modal, {
      onCloseModalSlogan: _cache[3] || (_cache[3] = ($event: any) => (_ctx.activeModalSlogan = false)),
      onAcceptSlogan: _ctx.sendAcceptSlogan,
      modelValue: _ctx.slogan,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.slogan) = $event))
    }, null, 8, ["onAcceptSlogan", "modelValue"]), [
      [_vShow, _ctx.activeModalSlogan]
    ]),
    _withDirectives(_createVNode(_component_rename_modal, {
      onCloseModalRename: _cache[5] || (_cache[5] = ($event: any) => (_ctx.activeModalRename = false)),
      onAcceptRename: _ctx.sendAcceptRename,
      modelValue: _ctx.rename,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.rename) = $event))
    }, null, 8, ["onAcceptRename", "modelValue"]), [
      [_vShow, _ctx.activeModalRename]
    ]),
    (_ctx.activeModalDisband)
      ? (_openBlock(), _createBlock(_component_disband_modal, {
          key: 0,
          onCloseDisbandModal: _cache[7] || (_cache[7] = ($event: any) => (_ctx.activeModalDisband = false)),
          onClickDisband: _ctx.sendDisbandSquade
        }, null, 8, ["onClickDisband"]))
      : _createCommentVNode("", true)
  ]))
}