
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import wallet from '@/lib/wallet'

import SloganModal from './slogan-modal.vue'
import RenameModal from './rename-modal.vue'
import DisbandModal from './disband-modal.vue'

export default defineComponent({
  name: 'YourSquadesContainer',
  components: { SloganModal, RenameModal, DisbandModal },
  props: {
    squadeId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      activeModalRename: false,
      activeModalSlogan: false,
      activeModalDisband: false,
      rename: '',
      slogan: ''
    }
  },

  methods: {
    sendAcceptSlogan() {
      wallet.cyberSquade_changeSlogan(this.squadeId, this.slogan)
      this.activeModalSlogan = false
    },
    sendAcceptRename() {
      wallet.cyberSquade_rename(this.squadeId, this.rename)
      this.activeModalRename = false
    },
    sendDisbandSquade() {
      wallet.cyberSquade_disbandSquade(this.squadeId)
      this.activeModalDisband = false
    }
  },

  computed: {
    ...mapGetters(['getUserSquades', 'getUserSquades', 'getSquadeTokens']),

    getSquade():any {
      return this.getUserSquades[this.squadeId] ? this.getUserSquades[this.squadeId] : {}
    },

    getSquadeBearz():any {
      return this.getUserSquades[this.squadeId] ? this.getUserSquades[this.squadeId] : {}
    },

    getSquadeTokensBearz():any {
      return this.getSquadeTokens ? this.getSquadeTokens : {}
    },

    getDailyIncome():any {
      if(this.getSquade && this.getSquade.dailyIncome) {
        return wallet.formatUnits(this.getSquade.dailyIncome)
      } else {
        return 0
      }
    }
  },
})
