
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { IChoiceOfTokenAndSlot } from '@/helpers/interface'
import wallet from "@/lib/wallet"

import ArmyStatsContainer from './components/army-stats-container.vue'
import YourSquadesContainer from './components/your-squades-container/index.vue'
import UserSoldiers from './components/user-soldiers/index.vue'
import NewCybersquadBlocksContainer from '@/components/new-cybersquad-blocks-container.vue'

export default defineComponent({
  name: 'MyArmy',
	components: { ArmyStatsContainer, YourSquadesContainer, UserSoldiers, NewCybersquadBlocksContainer },

	methods: {
		...mapActions(['fetchToken']),

		transferSelectedTokens(tokens:IChoiceOfTokenAndSlot):IChoiceOfTokenAndSlot {
			return tokens
		}
	},

	mounted () {
    console.log('asadsas')
    console.log(wallet.onboardState().address)
    if(!wallet.onboardState().address) {
      this.$router.push({name: 'Home'})
    }
		this.fetchToken()
	}

})
