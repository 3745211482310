import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55c4cdbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "warapper-cards" }
const _hoisted_2 = { class: "col-12 col-12-md col-12-lg cards" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_Title),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userTokensData, (item, index) => {
        return (_openBlock(), _createBlock(_component_Card, {
          key: index,
          img: item.metadata.image,
          title: item.metadata.name,
          tag: item.metadata.attributes
        }, null, 8, ["img", "title", "tag"]))
      }), 128))
    ])
  ]))
}