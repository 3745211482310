
import { defineComponent } from 'vue'

import Title from './components/title.vue'
import Card from './components/card.vue'

import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'Soldiers',
  components: { Title, Card },

  computed: {
   ...mapGetters(['userTokensData'])
  }
})
