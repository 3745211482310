
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'NewCybersquadSlot',
	props: {
		id: {
			type: Number,
			require: true,
			default: 0
		},
		newCyberSquadeContainerId: {
			type: Number,
			require: true,
			default: 0
		},
		chosenTokens: {
			type: Object,
			require: true,
			default: () => { return {id: '0'} }
		}
	},

	computed: {
		...mapGetters(['userTokensData']),

		gettingTokenImage():string {
			if (this.chosenTokens[this.id] == undefined) return require('@/assets/img/empty.png')
			else return this.userTokensData[this.chosenTokens[this.id]].metadata.image
		},

    getTokenName():string {
      if (this.chosenTokens[this.id] == undefined) return ''
      else return this.userTokensData[this.chosenTokens[this.id]].metadata.name
    }
	}
})
