import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3299b4d9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slot col card-img-a" }
const _hoisted_2 = ["id:number"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "name-slot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      "id:number": _ctx.id,
      class: "card-img card-img-a"
    }, [
      _createElementVNode("img", { src: _ctx.gettingTokenImage }, null, 8, _hoisted_3),
      _createElementVNode("a", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clickBtn', _ctx.id))),
        class: "button button-small button-choose secondary"
      }, " Choose ")
    ], 8, _hoisted_2),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.getTokenName), 1)
  ]))
}