
import { defineComponent, PropType } from 'vue'

import NewСybersquadSlot from './components/new-cybersquad-slot.vue'
import ModalWindow from './components/modal-window.vue'
import wallet from '@/lib/wallet'
import { NewCybersquadContainerData } from '@/helpers/interface'

export default defineComponent({
  name: 'NewCybersquadBlock',
  components: { NewСybersquadSlot, ModalWindow },

  data ():NewCybersquadContainerData{
    return {
      activeModal: false,
      choiceBlockId: 0,
      selectedЕokens: 0
    }
  },

  props: {
    id: {
      type: Number,
      default: 0
    },
    onTokenChosen: {
      type: Function,
      default: () => {""}
    },
    chosenTokens: {
      type: Object,
      default: () => {""},
      requery: true
    },
    allowedTokens: {
      type: Array as PropType<Array<number>>,
      default: () => []
    }
  },

  methods: {
    openModal(idSlot:number ):void {
      this.activeModal = true
      this.choiceBlockId = idSlot
    },

    checkingТumberOfTokens():void {
      if (Object.values(this.chosenTokens as number[]).length == 5) {
        wallet.cyberSquade_getSquadProfitability(Object.values(this.chosenTokens as any))
          .then(totalСost => this.selectedЕokens = wallet.formatUnits(totalСost))
          .catch(err => console.error(err))
      }
    },

    approvedForAll() {
      wallet.cyberSquad_createSquade_Safe(Object.values(this.chosenTokens as number[]))
    }
  },

  computed: {
    disabledBtn():any {
      let tokens:string[] = []

      for (let token in this.chosenTokens) {
        tokens.push(token)
      }

      if(tokens.length == 5) return false
      else return true
    }
  }
})
