import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f6d7c18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card card-soldier" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "card-content" }
const _hoisted_4 = { class: "card-title" }
const _hoisted_5 = { class: "card-tag" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", { src: _ctx.img }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h5", null, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tag, (item, key) => {
          return (_openBlock(), _createElementBlock("span", {
            key: key,
            class: "tag is-small"
          }, _toDisplayString(item.value), 1))
        }), 128))
      ]),
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ]))
}