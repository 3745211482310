import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_new_cybersquad_container = _resolveComponent("new-cybersquad-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getNewCybersquadsAllowed, (item, index) => {
      return (_openBlock(), _createBlock(_component_new_cybersquad_container, {
        key: index,
        id: index,
        onTokenChosen: _ctx.setChosenToken,
        chosenTokens: _ctx.chosenTokens[index],
        allowedTokens: _ctx.allowedTokens
      }, null, 8, ["id", "onTokenChosen", "chosenTokens", "allowedTokens"]))
    }), 128))
  ]))
}