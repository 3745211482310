import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a57845e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper-squades-container" }
const _hoisted_2 = { class: "col-12 col-12-md col-12-lg" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_squade_container = _resolveComponent("squade-container")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.getUserSquadesIds.length != 0)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_3, "Your Squades"))
        : _createCommentVNode("", true),
      (_ctx.getUserSquadesIds.length == 0)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_4, "YOU HAVE NO SQUADES YET, CREATE THE FIRST ONE BELOW"))
        : _createCommentVNode("", true)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getUserSquadesIds, (squade, index) => {
      return (_openBlock(), _createBlock(_component_squade_container, {
        key: index,
        squadeId: squade.toNumber()
      }, null, 8, ["squadeId"]))
    }), 128))
  ]))
}